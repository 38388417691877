import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdmissionsService {

  private api = 'https://akb.empire-dev.com/c';
  private apiTwo = `https://leads.empire.edu/dna/`;

  constructor( private http: HttpClient ) { }

  getMainCategories() {
    const url = this.api + `/mainCategories`;
    return this.http.get(url, {}).toPromise()
      .then( response => {
        return response;
      })
      .catch( error => {
        throw error;
      })
  }

  getAllContent() {
    const url = this.api + `/all`;
    return this.http.get(url, {}).toPromise()
      .then( response => {
        return response['content'];
      })
      .catch( error => {
        throw error;
      })
  }

  getContentByPath(path) {
    if (path === '/') {
      path = '/home';
    }
    const url = this.api + `/contentByPath` + `?path=${path}`;
    return this.http.get(url, {}).toPromise()
      .then( response => {
        return response['content'];
      })
      .catch( error => {
        throw error;
      })
  }


}
