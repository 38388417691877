import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    // loadChildren: () => import('./pages/homepage/homepage.module').then(m => m.HomepageModule)
    loadChildren: () => import('./pages/general/general.module').then(m => m.GeneralModule)
  },
  {
    path: '**',
    loadChildren: () => import('./pages/general/general.module').then(m => m.GeneralModule)
  }
  // {
  //   path: 'locator',
  //   loadChildren: () => import('./pages/locator/locator.module').then(m => m.LocatorModule)
  // },
  // {
  //   path: 'faq',
  //   loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule)
  // },
  // {
  //   path: 'value',
  //   loadChildren: () => import('./pages/value/value.module').then(m => m.ValueModule)
  // },
  // {
  //   path: 'sop',
  //   loadChildren: () => import('./pages/sop/sop.module').then(m => m.SopModule)
  // },
  // {
  //   path: 'tip',
  //   loadChildren: () => import('./pages/tips/tips.module').then(m => m.TipsModule)
  // },
  // {
  //   path: 'documents',
  //   loadChildren: () => import('./pages/documents/documents.module').then(m => m.DocumentsModule)
  // },
  // {
  //   path: 'campuses',
  //   loadChildren: () => import('./pages/campuses/campuses.module').then(m => m.CampusesModule)
  // },
  // {
  //   path: 'search',
  //   loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule)
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
